import React from "react";

const ContactMain = () => {

  return (
    <div className="w-full pt-[100px] pb-[40px]"  style={{ height: "calc(100vh - 97px"}}>
      <div className="main-container">
        <h3 className="text-dark text-[50px]  mb-4">Contact.</h3>

        <div className="flex gap-8  grid-cols-1 md:grid-cols-2 ">
          <div className="flex flex-col  md:basis-[42%]">
          <div className="text-[22px] text-dark capitalize">
            160 Robinson Road
              <br />
              #14-04 Singapore Business Federation Centre
              <br />
              Singapore (068914) <br />
              <br />
            </div>
            </div>
          </div>
          <div className="basis-[58%]">
            <div style={{ height: "443px", width: "100%" }}>
            <div className="mt-8">
              <form className="flex flex-col">
                <div className="min-h-[42px] border-b border-dark mb-[8px]">
                  <input
                    type={`name`}
                    placeholder="Name"
                    className="px-3 py-1 text-[13px] w-full h-full focus:outline-none"
                  />
                </div>
                <div className="min-h-[42px] border-b border-dark mb-[8px] ">
                  <input
                    type={`email`}
                    placeholder="Email"
                    className="px-3 py-1 text-[13px] w-full h-full focus:outline-none "
                  />
                </div>
                <div className="min-h-[42px] border-b border-dark mb-[8px] ">
                  <input
                    type={`phone`}
                    placeholder="Phone"
                    className="px-3 py-1 text-[13px] w-full h-full focus:outline-none "
                  />
                </div>
                <div className="min-h-[42px] border-b border-dark mb-[8px] ">
                  <textarea
                    placeholder="Message"
                    className="px-3 py-1 min-h-[100px] text-[13px] w-full h-full focus:outline-none resize-none"
                  ></textarea>
                </div>
                <div className="w-full flex">
                  <button className="min-h-[40px] flex justify-center text-center w-full hover:text-primary transition-all duration-300 items-center text-[13.3333px]">
                    Submit
                  </button>
                </div>
              </form>



            </div>
          </div>
        </div>
        <p className="mt-[200px] text-gray">© 2015 by Autopilot Limited</p>
      </div>
    </div>
  );
};

export default ContactMain;