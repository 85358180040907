import React from "react";
import Index from "../components/views/contact";
import { Helmet } from "react-helmet";
const Contact = ({ location }) => {
  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <Index location={location} />
    </>
  );
};

export default Contact;
